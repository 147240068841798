<template>
  <v-sheet style="background-color: #03266A; color: white;">
    <v-container class="py-8">
      <div
        class="d-flex flex-column flex-lg-row justify-space-between align-start"
      >
        <v-row>
          <v-col :cols="isMobile? '12':'4'">
            <v-img
              src="logo_n.png"
              width="250px">
            </v-img>
            <br/>
            <p>
              Copyrights © 2023 All Rights Reserved by CarTrust Co.,Ltd.<br/>
              Terms of Use / Privacy Policy
            </p>
          </v-col>
          <v-col :cols="isMobile? '12':'8'">
            <v-row :style="isMobile? 'padding-top: 0px;':'padding-top: 50px;'">
              <v-btn href="https://www.cartrust.co.th/" target="_blank" style="box-shadow: none; background-color: #03266A; color: white;">รู้จัก คาร์ทรัส</v-btn>
              <v-btn href="https://www.cartrust.co.th/" target="_blank" style="box-shadow: none; background-color: #03266A; color: white;">บริการของเรา</v-btn>
              <v-btn href="https://www.cartrust.co.th/" target="_blank" style="box-shadow: none; background-color: #03266A; color: white;">บทความและข่าวสาร</v-btn>
              <v-btn href="https://www.cartrust.co.th/" target="_blank" style="box-shadow: none; background-color: #03266A; color: white;">สมัครงาน</v-btn>
            </v-row>
            <v-row>
              <v-col :cols="isMobile? '12':'6'">
                <p>
                  <span style="font-weight: bold;">บริษัท คาร์ทรัส จำกัด :</span><br/>
                  252/91 (A) อาคารเมืองไทย-ภัทรคอมเพล็กซ์<br/>
                  ชั้นที่ 16 อาคาร บี ถนนรัชดาภิเษก แขวงห้วยขวาง<br/>
                  เขตห้วยขวาง กทม. 10310
                </p>
              </v-col>
              <v-col :cols="isMobile? '12':'6'">
                <v-row>
                  <p style="font-size: medium; padding-left: 10px;">
                    Call Center : 081-555-3555<br/>
                    Tel : 02-355-6121, 092-799-5854<br/>
                    Line : @CarTrustThailand
                  </p>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row style="padding-left: 10px;">
                      <v-col cols="3" style="padding-left: 0px;">
                        <a href="https://www.facebook.com/cartrustthailand" target="_blank"><v-img src="/images/logo/icon_facebook.png" style="width: 30px;"></v-img></a>
                      </v-col>
                      <v-col cols="3" style="padding-left: 0px;">
                        <a href="https://line.me/R/ti/p/@bwp2075y" target="_blank"><v-img src="/images/logo/icon_line.png" style="width: 30px;"></v-img></a>
                      </v-col>
                      <v-col cols="3" style="padding-left: 0px;">
                        <a href="https://www.facebook.com/cartrustthailand" target="_blank"><v-img src="/images/logo/icon_youtube.png" style="width: 30px;"></v-img></a>
                      </v-col>
                      <v-col cols="3" style="padding-left: 0px;">
                        <a href="https://www.tiktok.com/@cartrustthailand" target="_blank"><v-img src="/images/logo/icon_tiktok.png" style="width: 30px;"></v-img></a>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-btn style="width: -webkit-fill-available; background-color: #0139A1; color: white;">ติดต่อเรา</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>

</style>
